<!-- @/components/projects/ImageGrid.vue -->
<template>
  <div
      class="images-scroll-container"
      @wheel.passive="handleWheel"
      @scroll="handleScroll"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      ref="scrollContainerRef"
      :class="{ minimized: !isExpanded || currentProjectIndex !== projectIndex }"
  >
    <!-- Single centered thumbnail loading spinner for non-expanded view -->
    <div v-if="!isExpanded && projectLoadingStatus[project.id]?.isLoading" class="thumbnails-spinner-container">
      <div class="spinner"></div>
    </div>

    <div v-else class="images-container" :class="{ 'fade-in': !projectLoadingStatus[project.id]?.isLoading }">
      <div
          v-for="image in visibleImages"
          :key="image.id"
          class="image-wrapper"
          :class="{
            expanded: isExpanded && currentProjectIndex === projectIndex,
            active: isExpanded && currentProjectIndex === projectIndex &&
                   visibleImages.indexOf(image) === currentImageIndex
          }"
          @click="(event) => handleImageClick(visibleImages.indexOf(image), event)"
      >
        <img
            :src="getImageUrl(image, isExpanded && currentProjectIndex === projectIndex)"
            :alt="project.attributes.name"
            class="gallery-image"
            :class="{ expanded: isExpanded && currentProjectIndex === projectIndex }"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';

const props = defineProps({
  project: {
    type: Object,
    required: true
  },
  projectIndex: {
    type: Number,
    required: true
  },
  isExpanded: {
    type: Boolean,
    required: true
  },
  currentProjectIndex: {
    type: Number,
    required: true
  },
  currentImageIndex: {
    type: Number,
    required: true
  },
  projectLoadingStatus: {
    type: Object,
    required: true
  },
  visibleImages: {
    type: Array,
    default: () => []
  },
  getImageUrl: {
    type: Function,
    required: true
  },
  handleWheel: {
    type: Function,
    required: true
  },
  handleScroll: {
    type: Function,
    required: true
  },
  handleTouchStart: {
    type: Function,
    required: true
  },
  handleTouchMove: {
    type: Function,
    required: true
  },
  handleTouchEnd: {
    type: Function,
    required: true
  }
});

const emit = defineEmits(['image-selection']);

// Refs
const scrollContainerRef = ref(null);

// Expose this ref to parent component
defineExpose({
  scrollContainerRef
});

// Handle image click and emit to parent
const handleImageClick = (imageIndex, event) => {
  emit('image-selection', props.projectIndex, imageIndex, event);
};
</script>

<style lang="scss" scoped>
.images-scroll-container {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  position: relative;
  max-width: 100vw;

  &::-webkit-scrollbar {
    display: none;
  }

  &.minimized {
    overflow: auto;
  }

  &:not(.minimized) {
    overflow-x: auto;
    overflow-y: hidden;

    /* In expanded view, fill the remaining space after ProjectInfo */
    .project-row.expanded & {
      flex: 1; /* Take all remaining space */
      max-height: 100%; /* Prevent overflow */
    }
  }
}

.thumbnails-spinner-container {
  height: calc(15vh - 100px); /* Exactly match the images-container height */
  min-height: 70px; /* Same as images-container */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.images-container {
  height: calc(15vh - 100px);
  min-height: 70px;
  display: inline-flex;
  gap: 0 !important;
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  min-width: max-content;
  position: relative;

  /* In expanded view, fit within available space */
  .project-row.expanded & {
    height: 100%;
    max-height: 100%; /* Ensure it doesn't overflow */
  }

  &.fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }
}

.image-wrapper {
  flex: 0 0 auto;
  display: inline-block;
  height: 100%;
  position: relative;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: auto;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  /* In expanded view, ensure images fit within container */
  &.expanded {
    margin-right: 20px;
    height: 100%;
    object-fit: contain;
    width: auto;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 769px) {
  .image-wrapper {
    margin-right: 2px;
    &.expanded {
      margin-right: 20px;
    }
    &.last-child {
      margin-right: 0;
    }
  }
}

.gallery-image {
  height: 100%;
  object-fit: cover;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: auto;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.gallery-image.expanded {
  object-fit: contain;
  max-height: 100%;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
