<template>
  <div class="project-info" @click="handleHeaderClick">
    <div class="project-header">
      <h2>{{ name }}</h2>
      <span class="project-date">{{ date }}</span>
    </div>
    <div v-if="showDescription"
         class="project-description"
         @click.stop="toggleDescription"
    >
      <p>
        <span v-if="description" class="description-text">
          {{
            description.length > 120 && expanded
                ? description
                : description.slice(0, 120) +
                (description.length > 30 ? '...' : '')
          }}
        </span>
        <span
            v-if="description && description.length > 120"
            class="show-more"

        >
          {{ expanded ? '-' : '+' }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectInfo',
  props: {
    name: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    showDescription: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    handleHeaderClick() {
      this.$emit('click-header');
    },
    toggleDescription(event) {
      event.stopPropagation(); // Prevents the click from bubbling up
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss">
.project-info {
  z-index: 999;
  flex: 0 0 auto;
}

.project-header {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  flex: 0 1 auto;
  position: relative;
  z-index: 16;
}

.project-date {
  white-space: nowrap;
  flex: 0 0 auto; /* Don't grow or shrink, auto basis */
}

@media (max-width: 767px) {
  .image-wrapper {
    margin-right: 2px;
  }

  .project-header h2, .project-date {
    font-size: 2rem;
  }
}

.project-header h2,.project-header span  {
  font-weight: 100;
  font-size: 3rem;
  line-height: 0.9;
  margin: 0;
  text-transform: none;
}

.project-description {
  z-index: 20;
  margin: 2px 0;
  position: relative;
  cursor: pointer;
}

.project-description p {
  margin: 0;
  font-size: 1rem;
  line-height: 0.9;
}

.project-date {

  letter-spacing: -0.5px;
  line-height: 0.9;
  text-align: right;
}

.project-info.project-info-expanded {
  .project-header {
    width: 100%;
    text-align: left;
    padding-right: 0;

    /* Use standard text layout for expanded state */
    display: block;

    h2 {
      display: inline;
      margin-right: 0;
    }

    .project-date {
      display: inline;
      float: right;
      text-align: right;
    }

    /* Clear floats */
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  /* Mobile styles */
  @media (max-width: 767px) {
    .project-header {
      h2, .project-date {
        display: block;
        float: none;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.project-info.project-info-expanded .project-header .project-date {
  width: auto;
}

/* This catches when the date wraps to new line */
.project-info.project-info-expanded .project-header .project-date:only-child {
  width: 100%;
  float: none;
  text-align: left;
}
</style>