<template>
  <div>
    <div v-if="projects === null" class="projects-spinner-container">
      <div class="spinner"></div>
    </div>
    <Projects v-else :projects="sortedProjects" />
  </div>
</template>

<script>
import { fetchProjects } from '@/api';
import Projects from "@/components/Projects.vue";
import { useProjectStore } from '@/stores/projects';

export default {
  name: 'ProjectsView',
  components: {Projects},
  data() {
    return {
      projects: null,
      expandedProject: null,
    };
  },
  computed: {
    sortedProjects() {
      if (!this.projects) return [];

      const sorted = this.projects.slice().sort((a, b) => {
        const getYear = (date) => {
          if (!date) return 0;
          const years = date.split('-').map((year) => parseInt(year, 10));
          return Math.min(...years);
        };
        return getYear(a.attributes.date) - getYear(b.attributes.date);
      });

      // Simple console.log here for debugging
      console.log('sortedProjects:', sorted);

      return sorted;
    },
  },
  async mounted() {
    const startTime = Date.now();
    const MINIMUM_LOADING_TIME = 500;
    const projectStore = useProjectStore();

    try {
      // If projects are in Pinia store, use them
      if (projectStore.projects) {
        this.projects = projectStore.projects;
        return;
      }

      // Fetch projects (this will use your existing localStorage cache logic)
      const fetchedProjects = await fetchProjects();

      // Store in Pinia for component reuse
      projectStore.projects = fetchedProjects;

      // Ensure minimum loading time
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, MINIMUM_LOADING_TIME - elapsedTime);

      if (remainingTime > 0) {
        await new Promise(resolve => setTimeout(resolve, remainingTime));
      }

      this.projects = fetchedProjects;
    } catch (error) {
      console.error('Error loading projects:', error);
      // You might want to set projects to an empty array or handle error state
      this.projects = [];
    }
  }
};
</script>