<template>
  <div class="index">
    <!-- Initial spinner while fetching projects -->
    <div v-if="isInitialLoading" class="global-spinner-container">
      <div class="spinner"></div>
    </div>

    <div class="projects" :class="{ expanded: isExpanded }" v-else>
      <div
          class="projects-container"
          :class="{ expanded: isExpanded }"
          @keydown.esc="() => isExpanded = false"
          @keydown.left="() => navigate('previous')"
          @keydown.right="() => navigate('next')"
          tabindex="0"
          ref="containerRef"
      >
        <div
            v-for="(project, pIndex) in projectStore.visibleProjects"
            :key="project.id"
            class="project-row"
            :class="[
              projectClasses(pIndex),
              {'hidden-project': !projectLoadingStatus[project.id]?.thumbnailsLoaded}
            ]"
            :data-project-id="project.id"
        >
          <!-- Project thumbnail loading spinner -->
          <div v-if="projectLoadingStatus[project.id]?.isLoading && !projectLoadingStatus[project.id]?.thumbnailsLoaded"
               class="project-loading-spinner">
            <div class="spinner"></div>
            <div class="loading-text">Loading project...</div>
          </div>

          <!-- Project content -->
          <div class="project-content"
               v-if="projectLoadingStatus[project.id]?.thumbnailsLoaded"
               :class="{ 'fade-in': projectLoadingStatus[project.id]?.thumbnailsLoaded }">

            <ProjectInfo
                :name="project.attributes.name"
                :date="project.attributes.date"
                :description="project.attributes.description"
                @click-header="() => enhancedToggleExpand(pIndex)"
                :class="infoClasses(pIndex)"
                :showDescription="true"
            />

            <!-- Critical images loading spinner -->
            <div v-if="projectStore.isProjectLoading(project.id)" class="spinner-container">
              <div class="spinner"></div>
            </div>

            <ImageGrid
                :project="project"
                :projectIndex="pIndex"
                :isExpanded="isExpanded"
                :currentProjectIndex="currentProjectIndex"
                :currentImageIndex="currentImageIndex"
                :projectLoadingStatus="projectLoadingStatus"
                :visibleImages="getProjectVisibleImages(project.id)"
                :getImageUrl="getImageUrl"
                :handleWheel="handleWheel"
                :handleScroll="handleScroll"
                :handleTouchStart="handleTouchStart"
                :handleTouchMove="handleTouchMove"
                :handleTouchEnd="handleTouchEnd"
                @image-selection="handleImageSelection"
                ref="imageGridRefs"
            />
          </div>
        </div>

        <!-- Infinite scroll trigger -->
        <div ref="loadMoreTrigger" class="load-more-trigger"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, onUnmounted, nextTick, reactive, computed, watch } from 'vue';
import ProjectInfo from '@/components/projects/ProjectInfo.vue';
import ImageGrid from '@/components/projects/ImageGrid.vue';
import { useGallery } from '@/hooks/useGallery';
import { useProjectStore } from '@/stores/projects';
import { preloadProjectImages } from '@/utils/projectsLoader';

// Props are still useful for backward compatibility
const props = defineProps({
  projects: {
    type: Array,
    default: () => []
  }
});

const projectStore = useProjectStore();
const containerRef = ref(null);
const imageGridRefs = ref(null);
const loadMoreTrigger = ref(null);
const isInitialLoading = ref(true);
const savedScrollPosition = ref(0);
const projectVisibleImages = reactive({});
const projectLoadingStatus = reactive({});
const clickedImageRect = ref(null);

// Batch loading configuration
const BATCH_SIZE = 5; // Number of images to load at once

// Gallery hooks
const {
  isExpanded,
  currentProjectIndex,
  currentImageIndex,
  projectClasses,
  infoClasses,
  getImageUrl,
  navigate,
  toggleExpand,
  handleResize,
  handleWheel,
  handleScroll,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  disableScrollAnimation,
  enableScrollAnimation
} = useGallery(null, () => {
  // Get scroll container ref from current ImageGrid component
  if (imageGridRefs.value && currentProjectIndex.value !== null) {
    const grid = Array.isArray(imageGridRefs.value)
        ? imageGridRefs.value[currentProjectIndex.value]
        : imageGridRefs.value;

    return grid?.scrollContainerRef;
  }
  return null;
});

// Helper functions
const getProjectVisibleImages = (projectId) => {
  return projectVisibleImages[projectId] || [];
};

const getProjectAllImages = (project) => {
  if (!project?.attributes?.images?.data) return [];
  return project.attributes.images.data;
};

const enhancedToggleExpand = (pIndex) => {
  if (!isExpanded.value) {
    savedScrollPosition.value = window.pageYOffset || document.documentElement.scrollTop;
  }
  toggleExpand(pIndex);
  if (isExpanded.value === false) {
    nextTick(() => window.scrollTo(0, savedScrollPosition.value));
  }
};

// Load images in batches (same as in archive component)
const loadImageBatch = async (project) => {
  if (!project || !project.id) return;

  // Initialize project status if not already done
  if (!projectLoadingStatus[project.id]) {
    projectLoadingStatus[project.id] = {
      isLoading: true,
      currentIndex: 0,
      thumbnailsLoaded: false,
      initialBatchLoaded: false,
      loadedImageIds: new Set()
    };
  }

  // Initialize visible images array if needed
  if (!projectVisibleImages[project.id]) {
    projectVisibleImages[project.id] = [];
  }

  const status = projectLoadingStatus[project.id];

  // If already done loading, return
  if (!status.isLoading) return;

  // Get all images
  const allImages = getProjectAllImages(project);

  // If no images or already loaded all images, mark as done
  if (!allImages.length || status.currentIndex >= allImages.length) {
    status.isLoading = false;
    status.thumbnailsLoaded = true;
    status.initialBatchLoaded = true;
    return;
  }

  try {
    // Get next batch of images
    const batch = allImages.slice(
        status.currentIndex,
        status.currentIndex + BATCH_SIZE
    );

    // Filter out duplicates
    const newBatch = batch.filter(image => !status.loadedImageIds.has(image.id));

    // Add to loaded set
    newBatch.forEach(image => status.loadedImageIds.add(image.id));

    // Load all images in batch
    await preloadProjectImages(newBatch);

    // Add to visible images
    if (newBatch.length > 0) {
      projectVisibleImages[project.id].push(...newBatch);
    }

    // Update index
    status.currentIndex += BATCH_SIZE;

    // Set thumbnails as loaded after first batch
    if (!status.thumbnailsLoaded && projectVisibleImages[project.id].length > 0) {
      status.thumbnailsLoaded = true;
      status.initialBatchLoaded = true;
    }

    // If more to load, continue in next tick
    if (status.currentIndex < allImages.length) {
      nextTick(() => loadImageBatch(project));
    } else {
      status.isLoading = false;
    }
  } catch (error) {
    console.error('Error loading image batch:', error);
    status.isLoading = false;
    // Ensure thumbnails are shown even if there's an error
    status.thumbnailsLoaded = true;
    status.initialBatchLoaded = true;
  }
};

// Load images for all visible projects
const loadImagesForVisibleProjects = () => {
  if (!projectStore.visibleProjects?.length) return;

  projectStore.visibleProjects.forEach(project => {
    if (project) loadImageBatch(project);
  });
};

// Handle image selection
const handleImageSelection = async (projectIndex, imageIndex, event) => {
  if (isExpanded.value) return;
  savedScrollPosition.value = window.pageYOffset || document.documentElement.scrollTop;
  currentProjectIndex.value = projectIndex;
  currentImageIndex.value = imageIndex;

  if (event?.target) clickedImageRect.value = event.target.getBoundingClientRect();

  try {
    disableScrollAnimation();
    const project = projectStore.visibleProjects[projectIndex];
    if (!projectStore.projectsWithLoadedCriticalImages.has(project.id)) {
      await projectStore.loadCriticalImages(project, imageIndex);
    }

    isExpanded.value = true;
    nextTick(() => {
      const expandedContainer = document.querySelector('.project-row.expanded .images-scroll-container');
      if (expandedContainer) {
        const imageElements = expandedContainer.querySelectorAll('.image-wrapper');
        if (imageElements[imageIndex]) {
          const targetLeft = imageElements[imageIndex].offsetLeft;
          expandedContainer.scrollLeft = targetLeft;
          setTimeout(() => expandedContainer.scrollLeft = targetLeft, 10);
        }
      }
      setTimeout(() => enableScrollAnimation(), 50);
    });
  } catch (error) {
    console.error('Error during image selection:', error);
    enableScrollAnimation();
  }
};

// Observer setup
const infiniteScrollObserver = ref(null);
let projectsObserver = null;
let lazyLoadingObserver = null;

// Set up lazy loading
const setupLazyProjectLoading = () => {
  if (lazyLoadingObserver) lazyLoadingObserver.disconnect();
  nextTick(() => {
    const projectElements = document.querySelectorAll('.project-row');
    if (!projectElements.length) return;

    lazyLoadingObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const projectId = entry.target.getAttribute('data-project-id');
          const projectIndex = projectStore.visibleProjects.findIndex(p => p.id === projectId);
          if (projectIndex === -1) return;

          const nextIndex = projectIndex + 1;
          if (nextIndex < projectStore.visibleProjects.length) {
            const nextProject = projectStore.visibleProjects[nextIndex];
            if (nextProject && !projectLoadingStatus[nextProject.id]?.initialBatchLoaded) {
              loadImageBatch(nextProject);
              lazyLoadingObserver.unobserve(entry.target);
            }
          }
        }
      });
    }, { threshold: 0.3, rootMargin: '100px' });

    for (let i = 0; i < Math.min(projectElements.length, 5); i++) {
      lazyLoadingObserver.observe(projectElements[i]);
    }
  });
};

// Set up infinite scroll
const setupInfiniteScroll = () => {
  if (infiniteScrollObserver.value) infiniteScrollObserver.value.disconnect();
  nextTick(() => {
    if (!loadMoreTrigger.value) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && typeof projectStore.loadMoreProjects === 'function') {
        projectStore.loadMoreProjects();
      }
    }, { threshold: 0.1 });
    observer.observe(loadMoreTrigger.value);
    infiniteScrollObserver.value = observer;
  });
};

// Watch for changes to visibleProjects
watch(() => projectStore.visibleProjects.length, (newLength, oldLength) => {
  if (newLength !== oldLength) {
    nextTick(() => {
      setupInfiniteScroll();
      setupLazyProjectLoading();

      // Load images for newly added projects
      if (newLength > oldLength) {
        const newProjects = projectStore.visibleProjects.slice(oldLength);
        newProjects.forEach(project => {
          if (project) loadImageBatch(project);
        });
      }
    });
  }
});

// Watch for expanded state to preload adjacent projects
watch([isExpanded, currentProjectIndex], ([newExpanded, newIndex], [oldExpanded, oldIndex]) => {
  if (newExpanded && newIndex !== null && (newExpanded !== oldExpanded || newIndex !== oldIndex)) {
    const currentProject = projectStore.visibleProjects[newIndex];
    if (currentProject) {
      nextTick(() => {
        projectStore.preloadAdjacentProject('next', currentProject.id);
        projectStore.preloadAdjacentProject('previous', currentProject.id);
      });
    }
  }
});

// Watch for new projects
const watchForNewProjects = () => {
  const container = document.querySelector('.projects-container');
  if (!container) return null;

  const observer = new MutationObserver(() => {
    const newProjects = projectStore.visibleProjects.filter(project =>
        project && !projectLoadingStatus[project.id]?.initialBatchLoaded
    );
    if (newProjects.length > 0) {
      newProjects.forEach(project => loadImageBatch(project));
      setupLazyProjectLoading();
    }
  });

  observer.observe(container, { childList: true, subtree: true });
  return observer;
};

// Initialize projects in store if needed
if (projectStore.projects === null && props.projects?.length > 0) {
  projectStore.projects = props.projects;

  // Make sure visible projects are initialized
  if (projectStore.visibleProjects?.length === 0 && typeof projectStore.initVisibleProjects === 'function') {
    projectStore.initVisibleProjects();
  }
}

// Component lifecycle
onMounted(async () => {
  isInitialLoading.value = true;

  // Check if projects exist in the store
  if (!projectStore.projects) {
    await projectStore.loadProjects();
  }

  // Initialize visible projects if needed
  if (projectStore.sortedProjects.length > 0 && projectStore.visibleProjects.length !== projectStore.sortedProjects.length) {
    projectStore.visibleProjects = [...projectStore.sortedProjects];
  }

  isInitialLoading.value = false;

  // Load images for visible projects using the archive's approach
  nextTick(() => {
    loadImagesForVisibleProjects();

    // Set up watcher for new projects
    projectsObserver = watchForNewProjects();

    // Set up infinite scroll
    setupInfiniteScroll();

    // Set up lazy loading
    setupLazyProjectLoading();
  });

  // Set up resizing handler
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
  if (projectsObserver) projectsObserver.disconnect();
  if (lazyLoadingObserver) lazyLoadingObserver.disconnect();
});

onUnmounted(() => {
  if (infiniteScrollObserver.value) {
    infiniteScrollObserver.value.disconnect();
    infiniteScrollObserver.value = null;
  }
});
</script>

<style lang="scss" scoped>
.projects {
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(.expanded) {
    margin-top: 35vh;
  }
}

.global-spinner-container {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.9);

  .loading-text {
    margin-top: 15px;
    font-size: 16px;
    color: #333;
  }

  .spinner {
    width: 50px;
    height: 50px;
    border-width: 4px;
  }
}

.project-loading-spinner {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  .loading-text {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }
}

.projects-container {
  display: grid;
  position: relative;
  outline: none;
  width: 100%;
  overflow: hidden;
  gap: 20px;

  &.expanded {
    overflow: hidden; /* Contain overflow when expanded */
    height: calc(100vh - 25px);
  }

  &:last-child {
    gap: 0;
  }
}

.project-row {
  transition: all 300ms ease-in-out;
  opacity: 1;

  &.expanded {
    overflow: hidden;
    left: 0;
    width: 100%;
    position: fixed;
    margin: 0;
    z-index: 9999;
    background: white;
    height: calc(100vh - 25px); /* Subtract header height */
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
  }

  &.hidden-project {
    opacity: 0;
    max-height: 120px;
    overflow: hidden;
  }
}

.project-content {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
  opacity: 0; /* Start invisible */
  transition: opacity 0.5s ease-in-out;

  /* In expanded view, make it take the full height */
  .project-row.expanded & {
    max-height: calc(100vh - 25px); /* Ensure content doesn't exceed viewport minus header */
  }

  &.fade-in {
    opacity: 1;
  }
}

.spinner-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.load-more-trigger {
  height: 1px;
  width: 100%;
  background-color: transparent;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.inline-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #000;
  animation: spin 1s ease infinite;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000; /* Black color as per your design */
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s ease infinite;
}
</style>